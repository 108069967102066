import { createSignal, createEffect, Show } from "solid-js";
import cn from "classnames";

import { tagular } from "#cohesion/tagular";
import { TriangleDownFill } from "#icons";

interface Option {
  label: string;
  url: string;
}

interface FooterDropdownProps {
  className?: string;
  ghosted?: boolean;
  id: string;
  options: Option[];
  tagular?: Record<string, any>; // Adjust this type based on the shape of your tagularPayload
}

export function FooterDropdown({
  className = "",
  ghosted = false,
  id,
  options,
  tagular: tagularPayload = {},
}: FooterDropdownProps) {
  let el: HTMLDivElement | undefined;
  const [value, setValue] = createSignal<Option>(options[0]);
  const [isOpen, setIsOpen] = createSignal<boolean>(false);

  const onSelect = (val: Option) => {
    window.location.href = `https://${options.find((site) => site.label === val.label)?.url}`;
  };

  const handleSelect = (i: number) => {
    const val = options[i];

    setValue(val);
    onSelect(val);
    setIsOpen(false);

    if (tagular) {
      tagular("ElementClicked", {
        actionOutcome: "FILTER",
        webElement: {
          elementType: "DROPDOWN",
        },
        ...tagularPayload,
      });
    }
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (el && !el.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  createEffect(() => {
    if (isOpen()) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => document.removeEventListener("click", handleOutsideClick);
  });

  return (
    <div ref={el} id={id} class={cn("relative h-auto", className)}>
      <div class="relative">
        <button
          class={cn(
            "relative flex justify-between items-center w-full rounded-lg px-4 py-2 md:px-5 md:py-4 text-left font-semibold focus:outline-hidden border z-10",
            ghosted && !isOpen() ? "border-white" : "border-transparent",
            isOpen() ? "border-transparent" : "border-black-200",
            {
              "shadow-lg bg-white rounded-tr-none rounded-tl-none border-transparent":
                isOpen(),
            },
          )}
          onClick={() => setIsOpen(!isOpen())}
          type="button"
        >
          <p
            class={cn("leading-none", {
              "text-white": ghosted && !isOpen(),
            })}
          >
            {value().label}
          </p>
          <Show
            when={isOpen()}
            fallback={<TriangleDownFill className="text-xs ml-4 text-white" />}
          >
            <TriangleDownFill className="text-xs ml-4 text-blue" />
          </Show>
        </button>
        <ul
          class={cn(
            "absolute bottom-full w-full bg-white border border-b-0 border-black-100 rounded-tr-lg rounded-tl-lg py-2 shadow-lg-inverted",
            { hidden: !isOpen() },
          )}
        >
          {options.map((option, i) => {
            const { label } = option;

            if (options[i].label === value().label) return null;

            return (
              <li class="mx-1 md:mx-3">
                <button
                  class="w-full p-3 my-2 text-left rounded hover:bg-black-100 hover:font-semibold"
                  onClick={() => handleSelect(i)}
                  type="button"
                >
                  {label}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}